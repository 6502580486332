<template>
  <section>
    <b-table 
      id="tableDocument" 
      responsive 
      :stickyColumn="true" 
      :busy.sync="filtering" 
      :fields="tableDocument.fields"
      :items="tableDocument.items" 
      no-local-sorting 
      :no-sort-reset="true"
      @sort-changed="orderColumn"
    >
      <template #cell(descricao)="row">
        {{ row.item.anexo.descricao }}
      </template>
      <template #cell(criacao)="row">
          {{ getEnviadoEm(row) }}
      </template>
      <template #cell(usuario_criador)="row">
          {{ row.item.usuario_criador }}
      </template>
      <template #cell(acoes)="row">
        <feather-icon id="view-document" role="button" class="text-custom-blue mr-1" icon="EyeIcon" size="18"
          @click.prevent="viewGroupDocument(row.item.anexo.id_anexo)" />
        <feather-icon id="download-document" role="button" class="text-custom-blue mr-1" icon="DownloadIcon"
          @click.prevent="downloadArchive(row.item)" size="18" />
      </template>
    </b-table>
    <div class="pb-2 px-1">
      <span v-if="withoutDocument" class="ml-1">Nenhum documento encontrado</span>
      <b-col sm="12" class="mt-2">
        <CustomPagination :paginacao="paginationDataDocument" @page-cliked="updateCurrentPage" />
      </b-col>
    </div>
  </section>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend, BTab, BTabs } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import moment from 'moment';

export default {
  props: {
    group: {
      required: true
    }
  },

  components: {
    BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend,
    CustomPagination, BTab, BTabs, TableFilter
  },

  data() {
    return {
      filtering: false,
      paginationDataDocument: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      tableDocument: {
        fields: [
          { key: "descricao", label: "DOCUMENTAÇÃO E CONTRATOS", sortable: true, class: 'width_columns width_columns_header width_columns_header_company' },
          { key: "criacao", label: "ENVIADO EM", sortable: true, class: 'width_columns width_columns_header' },
          { key: "usuario_criador", label: "USUÁRIO", sortable: true, class: 'width_columns_small width_columns_header' },
          { key: "acoes", label: "AÇÕES", sortable: false, class: 'width_columns_small width_columns_header' }
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'id_grupo_documento',
        order: 'asc'
      },
      withoutDocument: false,
      lengthColumnMaximo: 28,
    }
  },

  mounted() {
    this.tableDocument.items = this.group.documento
    this.loadScheduleData()
  },

  methods: {
    downloadArchive(grupoDocumento) {
      this.$http.get(this.$api.grupoDocumentoId(grupoDocumento.id_grupo_documento), { responseType: 'arraybuffer' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = grupoDocumento.anexo.descricao
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },

    viewGroupDocument(idAnexo) {
      this.$http.get(this.$api.getDocumentFileById(idAnexo), {
        responseType: "blob",
        headers: {
          'Cache-Control': 'no-cache',
        }

      }).then(response => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank")
        URL.revokeObjectURL(url);
      })
    },

    updateCurrentPage(page) {
      this.paginationDataDocument.currentPage = page;
      const indexArrayCompanies = page - 1
      this.loadScheduleData(indexArrayCompanies)
    },

    async loadScheduleData(index = this.paginationDataDocument.currentPage - 1, order = false, sortOptions = {}) {
      this.paginationDataDocument.defaultSize = 10;

      let documents = this.splitOnPage(this.group.documento, 10)

      if(order) {
        documents = this.order(documents, sortOptions, index)
      }

      this.tableDocument.items = documents[index]
      this.withoutDocument = false;

      if (this.group.documento == null) {
        this.withoutDocument = true
      }

      this.startPagination(documents[index], index)
    },

    startPagination(documents, fromLine) {
      this.paginationDataDocument.totalLines = this.getTotalLines()
      this.paginationDataDocument.fromLine = this.getFromLine(fromLine, documents)
      this.paginationDataDocument.toLine = this.getToLine(documents, fromLine)
    },

    getTotalLines() {
      if (this.group.documento == null) {
        return 0
      }

      return this.group.documento.length
    },

    getFromLine(fromLine, documents) {
      if (documents) {
        return fromLine == 0 ? '1' : String(fromLine) + '1';
      }

      return fromLine == 0 ? '0' : String(fromLine) + '1';
    },

    getToLine(documents, fromLine) {
      const totalCompanies = documents != null ? documents.length : 0

      if (fromLine == 0) {
        return String(totalCompanies)
      }

      if (totalCompanies == 10) {
        return String(1 + fromLine) + '0'
      }

      return String(fromLine) + String(totalCompanies)

    },

    calculateTotalLines(documents) {
      let totalLines = 0

      if (documents == null) {
        return totalLines
      }

      totalLines = Math.ceil(documents.length / 10)

      return totalLines
    },

    splitOnPage(documents, itemPerPage) {
      if (documents == null) {
        return 1;
      }

      const pages = [];
      for (let i = 0; i < documents.length; i += itemPerPage) {
        pages.push(documents.slice(i, i + itemPerPage));
      }
      return pages;
    },

    orderColumn(sort) {
      this.tableDocument.columnOrder = sort.sortBy;
      this.tableDocument.order = sort.sortDesc ? 'desc' : 'asc';
      const sortOptions = {
        sortBy: sort.sortBy,
        sortDesc: sort.sortDesc
      }

      this.loadScheduleData(this.paginationDataDocument.currentPage - 1, true, sortOptions);
    },

    order(documents, sortOptions, index) {
      const self = this

      if (sortOptions.sortDesc) {
        documents[index].sort(function(a, b) {
          if(sortOptions.sortBy == 'criacao') {
            return self.parseDate(b.anexo[sortOptions.sortBy]) - self.parseDate(a.anexo[sortOptions.sortBy])
          }
          return b.anexo[sortOptions.sortBy].localeCompare(a.anexo[sortOptions.sortBy]);
        });
      }

      if (!sortOptions.sortDesc) {
        documents[index].sort(function(a, b) {
          if(sortOptions.sortBy == 'criacao') {
            return self.parseDate(a.anexo[sortOptions.sortBy]) - self.parseDate(b.anexo[sortOptions.sortBy])
          }
          return a.anexo[sortOptions.sortBy].localeCompare(b.anexo[sortOptions.sortBy]);
        });
      }

      return documents
    },

    parseDate(date) {
      const dateSplit = date.split('-')

      return new Date('20' + dateSplit[2].substring(0,2), dateSplit[1] - 1, dateSplit[0])
    },

    getEnviadoEm(row) {
      return moment(row.item.anexo.criacao).format('DD/MM/YYYY')
    },
  }
}

</script>